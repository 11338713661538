import {
    TranslocoMissingHandler,
    TranslocoMissingHandlerData,
} from '@jsverse/transloco';

export class CustomHandler implements TranslocoMissingHandler {
    handle(key: string, data: TranslocoMissingHandlerData): any {
        // console.warn(`Translation for ${key} was not found in ${data.activeLang}`);
        return null;
    }
}
