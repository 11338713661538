import { ResolveFn } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthenticationService } from '~/app/core/services/api/authentication/authentication.service';
import { inject } from '@angular/core';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';

export const profileResolver: ResolveFn<boolean> = (route, state) => {
    const authenticationService = inject(AuthenticationService);
    const authenticationFacade = inject(AuthenticationFacade);
    return authenticationService.user()
        .pipe(
            switchMap((user) => {
                authenticationFacade.updateUser(user);
                return of(true);
            }));
};
