import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import {
    catchError,
    combineLatest,
    filter,
    first,
    Observable,
    of,
    switchMap,
    withLatestFrom,
} from 'rxjs';

import { HTTP_ERROR_TYPES } from '~/app/shared/enums/http-error-type.enum';

import { NO_INTERCEPTOR_ERROR } from '~/app/shared/constants/common.constants';
import { SnackbarService } from '~/app/shared/services/snackbar/snackbar.service';
import { AuthenticationFacade } from '../../state/authentication/authentication.facade';
import { ErrorIgnoredMessagesEnum } from '~/app/shared/enums/error-ignored-messages.enum';
import { ErrorCustom } from '~/app/shared/types/error-custom.type';

function handleErrorResponse(
    error: HttpErrorResponse,
    {
        translocoService,
        snackbarService,
    }: { translocoService: TranslocoService, snackbarService: SnackbarService },
) {
    const errorObj: ErrorCustom = error?.error ? JSON.parse(JSON.stringify(error.error)) : null;
    snackbarService.openError(`${translocoService.translate('common.error_happen')}: ${errorObj?.detail ?? error.message}`);
}

function executeClonedRequest(
    request: HttpRequest<any>,
    headers: {
        token ?: string | null,
        instance ?: string | null,
        tenant ?: string | null,
        challengeToken ?: string | null,
    },
    next: HttpHandlerFn,
) {
    return next(
        request.clone({
            setHeaders: {
                ...(headers.token ? { Authorization: `Bearer ${headers.token}` } : {}),
                ...(headers.instance ? { Instance: `${headers.instance}` } : {}),
                ...(headers.tenant ? { Tenant: `${headers.tenant}` } : {}),
                ...(headers.challengeToken ? { Challenge: `${headers.challengeToken}` } : {}),
            },
        }),
    );
}

function handleExpiredCaptchaResponse(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandlerFn,
    {
        authFacade,
        translocoService,
        snackbarService,
        router,
    }: { authFacade: AuthenticationFacade, translocoService: TranslocoService, snackbarService: SnackbarService, router: Router },
): Observable<HttpEvent<any>> {
    const isChallengeRenewing = authFacade.getIsChallengeRenewingSnapshot();

    let obs;
    if (isChallengeRenewing === false) {
        obs = authFacade.resetChallenge();
    } else {
        obs = of(true);
    }

    return obs.pipe(
        switchMap(() => combineLatest([authFacade.challengeToken$, authFacade.isChallengeRenewing$])),
        filter(([, isChallengeRefreshed]) => isChallengeRefreshed === false),
        first(),
        switchMap(([challengeToken]) => {
            const headers = {
                token: authFacade.getTokenSnapshot(),
                challengeToken,
                tenant: authFacade.getTenantSnapshot(),
                instance: authFacade.getInstanceSnapshot(),
            };
            return executeClonedRequest(request, headers, next);
        }),
        catchError((err: HttpErrorResponse) => {
            const errorObj: ErrorCustom = JSON.parse(error.error);
            snackbarService.openError(`${translocoService.translate('common.error_happen')}: ${errorObj?.detail ?? err.message}`);
            void router.navigateByUrl('/unauthorised');
            throw err;
        }),
    );
}

function handle401UnauthorizedResponse(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandlerFn,
    {
        authFacade,
        translocoService,
        snackbarService,
        router,
    }: { authFacade: AuthenticationFacade, translocoService: TranslocoService, snackbarService: SnackbarService, router: Router },
): Observable<HttpEvent<any>> {
    const refreshToken = authFacade.getRefreshTokenSnapshot();
    if (!refreshToken || request.url.indexOf('refreshToken') !== -1) {
        if (authFacade.getUserSnapshot()) {
            authFacade.logout();
        }

        throw error;
    }

    return authFacade.refreshToken({ refreshToken })
        .pipe(
            withLatestFrom(authFacade.token$),
            switchMap(([_, token]) => {
                const headers = {
                    token,
                    challengeToken: authFacade.getChallengeTokenSnapshot(),
                    tenant: authFacade.getTenantSnapshot(),
                    instance: authFacade.getInstanceSnapshot(),
                };
                return executeClonedRequest(request, headers, next);
            }),
            catchError((err: HttpErrorResponse) => {

                if (err.status === 401 && err.error?.type !== HTTP_ERROR_TYPES.CHALLENGE_INVALID) {
                    authFacade.logout();
                    throw err;
                }

                if (err.status === 401 && err.error?.type === HTTP_ERROR_TYPES.CHALLENGE_INVALID) {
                    return handleExpiredCaptchaResponse(error, request, next, {
                        authFacade,
                        snackbarService,
                        translocoService,
                        router,
                    });
                }

                return handleFailedRequest(err, request, next, {
                    authFacade,
                    snackbarService,
                    translocoService,
                    router,
                });
            }),
        );
}

function handleFailedRequest(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandlerFn,
    {
        authFacade,
        translocoService,
        snackbarService,
        router,
    }: { authFacade: AuthenticationFacade, translocoService: TranslocoService, snackbarService: SnackbarService, router: Router },
): Observable<HttpEvent<any>> {
    if (error.status === 401) {

        if (error.error?.type === HTTP_ERROR_TYPES.CHALLENGE_INVALID) {
            return handleExpiredCaptchaResponse(error, request, next, {
                authFacade,
                snackbarService,
                translocoService,
                router,
            });
        }


        if (error.error?.type !== HTTP_ERROR_TYPES.CHALLENGE_INVALID) {
            return handle401UnauthorizedResponse(error, request, next, {
                authFacade,
                snackbarService,
                translocoService,
                router,
            });
        }
    }

    if (error.status === 400 || error.status === 404 || error.status === 500) {
        const errorIgnoredByMessage: string[] = Object.values(ErrorIgnoredMessagesEnum).filter(() => true);
        if (
            !request.context.get(NO_INTERCEPTOR_ERROR)
            && !errorIgnoredByMessage.includes(error?.error?.detail)
        ) {
            handleErrorResponse(error, {
                translocoService,
                snackbarService,
            });
        }
    }
    throw error;
}

export function requestInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {


    const authFacade = inject(AuthenticationFacade);
    const translocoService = inject(TranslocoService);
    const snackbarService = inject(SnackbarService);
    const router = inject(Router);

    const instance = authFacade.getInstanceSnapshot();
    const tenant = authFacade.getTenantSnapshot();
    const token = authFacade.getTokenSnapshot();
    const challengeToken = authFacade.getChallengeTokenSnapshot();

    const headers = {
        token,
        instance,
        tenant,
        challengeToken,
    };

    return executeClonedRequest(req, headers, next).pipe(
        catchError((error: HttpErrorResponse) => handleFailedRequest(error, req, next, {
            authFacade,
            translocoService,
            snackbarService,
            router,
        })),
    );
}
